














































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import * as _ from "lodash";
import { ColumnStyleItem, ReportDataTable, ReportDataTableHeader, TotalsAggregateValue } from '@/store/models';
import { EmptyReportDataTableRow } from '@/store/models-empty';
import { addPercentageToReportDataTable, formatCurrencyToString } from '@/lib/shared';

@Component
export default class ReportDataTableComponent extends Vue {
  @Prop() private reportData: any;
  @Prop() private reportMode: any;
  @Prop() private totalsRow: any;
  @Prop() private totalsRowAggregate: any;

  private isLoaded: boolean = false;
  private reportDataCopy: ReportDataTable|null = null;
  private totalsRowColumnStyle: ColumnStyleItem[] = [ { index: 0, fontSize: '16px' }, { index: 1, fontSize: '16px' }, { index: 2, fontSize: '16px' } ];

  private mounted() {
    this.reportDataCopy = _.cloneDeep(this.reportData);
    this.autoCalculateTotalRow(this.reportDataCopy as ReportDataTable);
    this.addPercentageToReportDataTable(this.reportDataCopy as ReportDataTable, this.totalsRowAggregate as TotalsAggregateValue, this.totalsRow as string[]);
    this.isLoaded = true;
  }

  private autoCalculateTotalRow(reportData: ReportDataTable) {
    if (reportData.autoCalculateTotalRow && reportData.rows[0] !== undefined) {
      reportData.totalRow = _.cloneDeep(EmptyReportDataTableRow);
      const totalColumns = reportData.rows[0].data.length;
      for (let columnIndex = 1; columnIndex < totalColumns; columnIndex++) {
        const columnTotal: string = this.getColumnTotal(reportData, columnIndex).toString();
        reportData.totalRow.data[columnIndex - 1] =
          (reportData.rows[0].data[columnIndex] as string).includes('$')
            ? formatCurrencyToString(columnTotal)
            : columnTotal;
      }
    }
  }

  private addPercentageToReportDataTable(reportData: ReportDataTable, totalsRowAggregate: TotalsAggregateValue, totalsRow: string[]) {
    if (totalsRowAggregate) {
      reportData = addPercentageToReportDataTable(reportData, totalsRowAggregate, totalsRow, this.totalsRowColumnStyle);
    }
  }

  private getColumnTotal(reportData: ReportDataTable, columnIndex: number): number {
    let totalAmount: number = 0;
    for (const row of reportData.rows) {
      const rowDataValue = (row.dataValues as any[])[columnIndex];
      totalAmount += (rowDataValue ? rowDataValue : 0);
    }
    return totalAmount;
  }

  private isTableHidden(reportData: ReportDataTable): boolean {
    return (reportData.hideIfZeroTotal && reportData.total.value === 0);
  }

  private getContainerClass(reportMode: string) {
    return reportMode !== '' ? 'report-data-table-container-report' : 'report-data-table-container';
  }

  private getTitleContainerClass(reportMode: string) {
    return reportMode !== '' ? 'report-data-table-title-container-report' : 'report-data-table-title-container';
  }

  private getTitleClass(reportMode: string) {
    return reportMode !== '' ? 'item-legend-title-report' : 'item-legend-title';
  }

  private getTitleAmountClass(reportMode: string) {
    return reportMode !== '' ? 'item-legend-title-amount-report' : 'item-legend-title-amount';
  }

  private getTitlePercentageClass(reportMode: string) {
    return reportMode !== '' ? 'item-legend-title-percentage-report' : 'item-legend-title-percentage';
  }

  private getHeaderClass(header: ReportDataTableHeader, reportMode: string): string {
    return 'report-data-table-header ' + (reportMode !== '' ? 'grid-bold-text-report ' : 'grid-bold-text ') + 'xs' + header.rowLayout.toString() + ' text-xs-' + header.align;
  }

  private getColumnClass(index: number, mainClass: string, reportMode: string, isBold: boolean = false, columnStyle: ColumnStyleItem[] = []): string {
    const header: ReportDataTableHeader = this.reportData.headers[index];
    const columnBold: boolean = columnStyle.find((i) => i.index === index && i.isBold) !== undefined;
    const columnItalic: boolean = columnStyle.find((i) => i.index === index && i.isItalic) !== undefined;
    return header ? mainClass + ' xs' + header.rowLayout.toString() + (' text-xs-' + header.align) + ((isBold || columnBold) ? reportMode !== '' ? ' grid-bold-text-report' : ' grid-bold-text' : '') + (columnItalic ? ' grid-italic-text' : '') : '';
  }

  private getColumnStyle(index: number, columnStyle: ColumnStyleItem[] = []): string {
    const header: ReportDataTableHeader = this.reportData.headers[index];
    const styleItem: ColumnStyleItem | undefined = columnStyle.find((i) => i.index === index);
    const fontSize: string = styleItem !== undefined ? styleItem.fontSize ? styleItem.fontSize : '' : '';
    const indentations: number = styleItem !== undefined ? styleItem.indentations ? styleItem.indentations : 0 : 0;
    const percentage = (header.rowLayout / 12) * 100;
    const textColour = styleItem !== undefined ? styleItem.textColour ? styleItem.textColour : '' : '';
    return 'width:' + percentage.toString() + '%;' + (fontSize !== '' ? 'font-size:' + fontSize + ';' : '') + (textColour !== '' ? 'color:' + textColour + ';' : '') + (indentations > 0 ? ('padding-left:' + (24 + (indentations * 50)) + 'px;') : '');
  }

  private getDataTableClass(reportData: ReportDataTable, reportMode: string): string {
    return (reportData.className !== '' ? reportData.className + ' ' : '' ) + (reportMode !== '' ? 'report-data-table-container' : 'web-data-table-container') + (reportData.pageBreakAfter ? ' page-break-after' : '');
  }

  private getTitleStyle(reportData: ReportDataTable, reportMode: string): string {
    return 'background-color: ' + (reportMode !== '' ? '#FFFFFF' : reportData.color);
  }

  private getData(item: string | TotalsAggregateValue): string {
    if (typeof item === 'string') {
      return item as string;
    } else if (item === undefined) {
        return "";
    } else {
      const totalsItem = item as TotalsAggregateValue;
      return totalsItem.valueFormatted + ' (' + totalsItem.percentageFormatted + ')';
    }
  }

  private getColSpan(item: ReportDataTableHeader): number {
    if (item && item.rowLayout) {
      return item.rowLayout;
    }
    return 1;
  }
}
